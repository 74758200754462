import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { baseTasksTemplate } from '@/modules/daiwa-house-modular-europe/PhaseTransfer/baseTasksTemplate';
import { importTasksV2, queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import moment from 'moment';

export const startPhaseAndGenerateTasks = async (project, focusedPhase) => {
  let assemblyTasks = [];
  if (focusedPhase.task_type.type === 'dhme-assembly-phase') {
    assemblyTasks = await queryTasksV2(project.license, [
      {
        column: 'project',
        operator: '=',
        values: [project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: ['dhme-module-assembly'],
      },
    ]);
  }
  // fetch project risk measurements
  let projectRiskMeasures = await fetchProjectRiskMeasurements(project.id);

  let measurements = await fetchMeasurements(
    project.master_id,
    projectRiskMeasures.map((r) => r.measurement_id)
  );

  let modules = await fetchProjectModules(project.id);

  // fetch forms, docs, tasks
  let items = await fetchFormsDocsAndTasksInPhase(
    project.master_id,
    focusedPhase.task_type.type,
    measurements
  );

  let qcTasks = [];
  items.forEach((item) => {
    const measurement = measurements.find((m) => m.id === item.measurement_id);
    measurement.sample_size =
      measurement.type === 'leanform'
        ? projectRiskMeasures.find((x) => x.measurement_id === measurement.id)
            .sample_size
        : 100;

    const moduleCount = getModulesBasedOnSampleLevel(measurement, modules);

    moduleCount.forEach((module) => {
      let obj = {
        parent: focusedPhase.id,
        title: item.name ?? `Form: ${item.lf_form}`,
        description: `Measurement: ${measurement.name}`,
        project: project.id,
        sbscode: module.build_nr,
        defaultDoc: item?.default ?? null,
        options: {
          type: 'dhme-quality-assurance',
          custom_1: item.id,
          custom_2: measurement.type,
          custom_3: item.phase,
          custom_4: item?.lf_form ?? null,
          custom_5: item?.lf_project ?? null,
          custom_6: module.module_id,
          custom_7: null,
          metadata: JSON.stringify(item),
          executable: true,
        },
      };

      if (focusedPhase.task_type.type === 'dhme-assembly-phase') {
        obj.parent =
          assemblyTasks.find((t) => t.sbscode.code === module.build_nr)?.id ??
          focusedPhase.id;
      }

      qcTasks.push(obj);
    });
  });

  let baseTasks = [];
  if (baseTasksTemplate[focusedPhase.task_type.type]) {
    baseTasks = [...baseTasksTemplate[focusedPhase.task_type.type]];

    const duration = moment(focusedPhase.planned_end).diff(
      moment(focusedPhase.planned_start),
      'days'
    );
    let date = moment(focusedPhase.planned_start);

    baseTasks.forEach((t) => {
      const endDate = date
        .clone()
        .add(duration * t.duration, 'days')
        .format('YYYY-MM-DD');

      t.parent = focusedPhase.id;
      t.project = project.id;
      t.planned_start = date.clone().format('YYYY-MM-DD');
      t.planned_end = endDate;
      t.due = endDate;
    });
  }

  const tasks = [
    ...[
      {
        id: focusedPhase.id,
        title: focusedPhase.title,
        project: focusedPhase.project,
        options: {
          custom_10: 'initialised',
        },
      },
    ],
    ...baseTasks,
    ...qcTasks,
  ];

  return importTasksV2(tasks);
};

async function fetchFormsDocsAndTasksInPhase(projectId, type, measurements) {
  const { tasks, forms, documents } = await queryTablesV2({
    tables: [
      {
        name: 'CFFA_DHME_WKB_MEASUREMENT_TASKS',
        project: projectId,
        as: 'tasks',
        columns: [
          {
            name: 'measurement_id',
            conditions: [
              {
                operator: '=',
                values: measurements
                  .filter((m) => m.type === 'task')
                  .map((m) => m.id),
              },
            ],
          },
          {
            name: 'phase',
            conditions: [
              {
                operator: '=',
                value: type,
              },
            ],
          },
          {
            name: 'name',
          },
          {
            name: 'number',
          },
        ],
      },
      {
        name: 'CFFA_DHME_WKB_MEASUREMENT_FORMS',
        project: projectId,
        as: 'forms',
        columns: [
          {
            name: 'measurement_id',
            conditions: [
              {
                operator: '=',
                values: measurements
                  .filter((m) => m.type === 'leanform')
                  .map((m) => m.id),
              },
            ],
          },
          {
            name: 'phase',
            conditions: [
              {
                operator: '=',
                value: type,
              },
            ],
          },
          {
            name: 'lf_form',
          },
          {
            name: 'lf_project',
          },
        ],
      },
      {
        name: 'CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS',
        project: projectId,
        as: 'documents',
        columns: [
          {
            name: 'measurement_id',
            conditions: [
              {
                operator: '=',
                values: measurements
                  .filter((m) => m.type === 'document')
                  .map((m) => m.id),
              },
            ],
          },
          {
            name: 'phase',
            conditions: [
              {
                operator: '=',
                value: type,
              },
            ],
          },
          {
            name: 'name',
          },
          {
            name: 'number',
          },
        ],
      },
    ],
  });
  return [...tasks.records, ...forms.records, ...documents.records];
}

async function fetchProjectModules(projectId) {
  const { modules } = await queryTablesV2({
    tables: [
      {
        name: 'CFFA_DHME_MODULES',
        project: projectId,
        as: 'modules',
        columns: [
          {
            name: 'module_id',
          },
          {
            name: 'module_type',
          },
          {
            name: 'build_nr',
          },
          {
            name: 'house_nr',
          },
        ],
      },
    ],
  });
  return modules.records;
}
async function fetchProjectRiskMeasurements(projectId) {
  const { projectRiskMeasures } = await queryTablesV2({
    tables: [
      {
        name: 'CFFA_DHME_WKB_PROJECT_RISKS_MEASURES',
        project: projectId,
        as: 'projectRiskMeasures',
        columns: [
          {
            name: 'risk_id',
          },
          {
            name: 'measurement_id',
          },
          {
            name: 'sample_size',
          },
        ],
      },
    ],
  });
  return projectRiskMeasures.records;
}

async function fetchMeasurements(projectId, recordIds) {
  const { measurements } = await queryTablesV2({
    tables: [
      {
        name: 'CFFA_DHME_WKB_MEASUREMENTS',
        project: projectId,
        as: 'measurements',
        records: recordIds,
        columns: [
          {
            name: 'name',
          },
          {
            name: 'type',
          },
          {
            name: 'party',
          },
          {
            name: 'sample_level',
          },
        ],
      },
    ],
  });
  return measurements.records;
}

function getModulesBasedOnSampleLevel(measurement, modules) {
  switch (measurement.sample_level) {
    case 'Project':
      let shuffledModules = [...modules].sort(() => {
        return 0.5 - Math.random();
      });
      if (measurement.sample_size > 0) {
        return shuffledModules.slice(0, 1);
      } else {
        return [];
      }
    case 'Modules':
      let moduleMeasurePercentile = Math.ceil(
        modules.length * (measurement.sample_size / 100)
      );

      let items = modules.slice(0, moduleMeasurePercentile);

      let moduleItems = [];
      items.forEach(() => {
        let tmp = modules.sort(() => {
          return 0.5 - Math.random();
        });

        moduleItems.push(tmp[0]);
      });

      return moduleItems;
    case 'Bouwblokken':
      let buildBlocks = [
        ...new Set(
          modules
            .filter((module) => module.build_nr)
            .map((module) => module?.build_nr?.charAt(0))
        ),
      ];
      let buildBlocksPercentile = Math.ceil(
        buildBlocks.length * (measurement.sample_size / 100)
      );
      let measuredBlocks = buildBlocks.slice(0, buildBlocksPercentile);

      let buildBlockModules = [];

      measuredBlocks.forEach((block) => {
        let shuffledModules = [...modules]
          .filter((module) => module.build_nr.startsWith(block))
          .sort(() => {
            return 0.5 - Math.random();
          });
        buildBlockModules.push(shuffledModules[0]);
      });

      return buildBlockModules;
    case 'Types':
      let moduleTypes = [
        ...new Set(modules.map((module) => module.module_type)),
      ];
      let moduleTypePercentile = Math.ceil(
        moduleTypes.length * (measurement.sample_size / 100)
      );
      let measuredTypes = moduleTypes.slice(0, moduleTypePercentile);

      let typeModules = [];
      measuredTypes.forEach((type) => {
        let shuffledModules = [...modules]
          .filter((module) => module.module_type === type)
          .sort(() => {
            return 0.5 - Math.random();
          });
        typeModules.push(shuffledModules[0]);
      });

      return typeModules;
    case 'Bouwlagen':
      let buildLayers = [
        ...new Set(modules.map((module) => module?.build_nr?.substring(0, 1))),
      ];

      let buildLayersPercentile = Math.ceil(
        buildLayers.length * (measurement.sample_size / 100)
      );
      let measuredBuildLayers = buildLayers.slice(0, buildLayersPercentile);

      let buildLayerModules = [];
      measuredBuildLayers.forEach((layer) => {
        let shuffledModules = [...modules]
          .filter((module) => module.build_nr.charAt(1) === layer)
          .sort(() => {
            return 0.5 - Math.random();
          });
        buildLayerModules.push(...shuffledModules[0]);
      });

      return buildLayerModules;
  }
}
